// Any changes to this should be kept in sync on the back end with src/structures/roleIdEnum.js
module.exports = {
    PRG_ADMIN: 1,
    VIEW_ONLY: 3,
    TEL_INVESTIGATOR: 5,
    FIELD_INVESTIGATOR: 18,
    RECOVERY_AGENT: 19,
    CLAIMS_ADMIN: 20,
    TEL_FIELD_MANAGER: 28,
    PG_DIRECTOR: 29,
    CLAIMS_ADMIN_MANAGER: 30,
    OPD_NATIONAL_CLAIMS_MANAGER: 31,
    DISPATCH_ADMIN: 38,
    PRG_ADMIN_2: 39,
    CLAIMS_RECOVERY_DIRECTOR: 1041,
    CLAIMS_MANAGER: 1042,
    ATTORNEY: 1043,
    LEGAL_DESK: 1044,
    RECOVERY_PROJECT_COORDINATOR: 1045,
    QA_RECOVERY: 1046,
    LOCATE_DESK: 1047,
    RECOVERY_SUPERVISOR: 1048,
    TEL_DIRECTOR: 1049,
    TEL_TRAVEL_INVESTIGATOR: 1051,
    TEL_JOB_COSTING: 1052,
    TEL_FIELD_SUPERVISOR: 1053,
    PG_FIELD_SUPERVISOR: 1054,
    PG_REGIONAL_MANAGER: 1055,
    PG_JOB_COSTING: 1058,
    PG_FIELD_MANAGER: 1059,
    PG_FIELD_LEAD: 1061,
    PG_INVESTIGATOR: 1062,
    DISPATCH: 1063,
    ACCOUNTING: 1064,
    COMCAST_EXT: 1068,
    CHARTER_EXT: 1069,
    DUKE_ENERGY_EXT: 1070,
    TEL_AREA_MANAGER: 1072,
    PG_JOB_COSTING_MANAGER: 1073,
    PG_QA: 1057,
    TEL_FIELD_MANAGER_2: 1076,
    TEL_FIELD_MANAGER_3: 1077,
    TEL_FIELD_MANAGER_4: 1078,
    SVEC_EXT: 1081,
    BREC_EXT: 1082,
    NATIONAL_GRID_EXT: 1083,
    CHARTER_BOLD_EXT: 1084,
    ALTICE_EXT: 1085,
    ASTOUND_EXT: 1086,
    ATT_EXT: 1087,
    BOLDYN_EXT: 1088,
    COMPORIUM_EXT: 1089,
    EVERSTREAM_EXT: 1090,
    EZEE_FIBER_EXT: 1091,
    FIBERLIGHT_EXT: 1092,
    FIRSTLIGHT_EXT: 1093,
    GOOGLE_FIBER_EXT: 1094,
    INTERSECTION_EXT: 1095,
    MCNC_EXT: 1096,
    MEDIACOM_EXT: 1097,
    NCEC_EXT: 1098,
    NORTHLAND_EXT: 1099,
    PHONOSCOPE_EXT: 1100,
    PS_LIGHTWAVE_EXT: 1101,
    ROGERS_EXT: 1102,
    SEGRA_EXT: 1103,
    TING_EXT: 1104,
    WOW_EXT: 1105,
    ZAYO_EXT: 1106,
    TEL_MOBILE_INVESTIGATOR: 1107,
    DUKE_ENERGY_2_EXT: 1110,
    CHARTER_BOLD_LEADERSHIP_EXT: 1111,
    WCTEL_BREC_EXT: 1114,
}