import * as roles from './roleIdEnum';

const roleGroups = {
    TEL_EXTERNAL: [
        roles.COMCAST_EXT,
        roles.CHARTER_EXT,
        roles.CHARTER_BOLD_EXT,
        roles.ALTICE_EXT,
        roles.ASTOUND_EXT,
        roles.ATT_EXT,
        roles.BOLDYN_EXT,
        roles.COMPORIUM_EXT,
        roles.EVERSTREAM_EXT,
        roles.EZEE_FIBER_EXT,
        roles.FIBERLIGHT_EXT,
        roles.FIRSTLIGHT_EXT,
        roles.GOOGLE_FIBER_EXT,
        roles.INTERSECTION_EXT,
        roles.MCNC_EXT,
        roles.MEDIACOM_EXT,
        roles.NCEC_EXT,
        roles.NORTHLAND_EXT,
        roles.PHONOSCOPE_EXT,
        roles.PS_LIGHTWAVE_EXT,
        roles.ROGERS_EXT,
        roles.SEGRA_EXT,
        roles.TING_EXT,
        roles.WOW_EXT,
        roles.ZAYO_EXT,
        roles.CHARTER_BOLD_LEADERSHIP_EXT,
    ],
    PG_EXTERNAL: [
        roles.DUKE_ENERGY_EXT,
        roles.SVEC_EXT,
        roles.BREC_EXT,
        roles.NATIONAL_GRID_EXT,
        roles.DUKE_ENERGY_2_EXT,
        roles.WCTEL_BREC_EXT,
    ],


    TEL_EXT_ENTITY_MAP: {
        [roles.COMCAST_EXT]: {legacyEntityId: 4, jointEntityId: 4},
        [roles.CHARTER_EXT]: {legacyEntityId: 23, jointEntityId: 20},
        [roles.CHARTER_BOLD_EXT]: {legacyEntityId: 23, jointEntityId: 20},
        [roles.CHARTER_BOLD_LEADERSHIP_EXT]: {legacyEntityId: 23, jointEntityId: 20},
        [roles.ALTICE_EXT]: {legacyEntityId: 31, jointEntityId: 28},
        [roles.ASTOUND_EXT]: {legacyEntityId: 17, jointEntityId: 14},
        [roles.ATT_EXT]: {legacyEntityId: 35, jointEntityId: 32},
        [roles.BOLDYN_EXT]: {legacyEntityId: 27, jointEntityId: 24},
        [roles.COMPORIUM_EXT]: {legacyEntityId: 20, jointEntityId: 17},
        [roles.EVERSTREAM_EXT]: {legacyEntityId: 39, jointEntityId: 36},
        [roles.EZEE_FIBER_EXT]: {legacyEntityId: 40, jointEntityId: 37},
        [roles.FIBERLIGHT_EXT]: {legacyEntityId: 47, jointEntityId: 79},
        [roles.FIRSTLIGHT_EXT]: {legacyEntityId: 30, jointEntityId: 27},
        [roles.GOOGLE_FIBER_EXT]: {legacyEntityId: 28, jointEntityId: 25},
        [roles.INTERSECTION_EXT]: {legacyEntityId: 29, jointEntityId: 26},
        [roles.MCNC_EXT]: {legacyEntityId: 18, jointEntityId: 15},
        [roles.MEDIACOM_EXT]: {legacyEntityId: 22, jointEntityId: 19},
        [roles.NCEC_EXT]: {legacyEntityId: 24, jointEntityId: 21},
        [roles.NORTHLAND_EXT]: {legacyEntityId: 33, jointEntityId: 30},
        [roles.PHONOSCOPE_EXT]: {legacyEntityId: 26, jointEntityId: 23},
        [roles.PS_LIGHTWAVE_EXT]: {legacyEntityId: 25, jointEntityId: 22},
        [roles.ROGERS_EXT]: {legacyEntityId: 44, jointEntityId: 40},
        [roles.SEGRA_EXT]: {legacyEntityId: 19, jointEntityId: 16},
        [roles.TING_EXT]: {legacyEntityId: 46, jointEntityId: 78},
        [roles.WOW_EXT]: {legacyEntityId: 37, jointEntityId: 34},
        [roles.ZAYO_EXT]: {legacyEntityId: 36, jointEntityId: 33},
    },
    PG_EXT_ENTITY_MAP: {
        [roles.DUKE_ENERGY_EXT]: {legacyEntityId: 3, jointEntityId: 43},
        [roles.SVEC_EXT]: {legacyEntityId: 6, jointEntityId: 46},
        [roles.BREC_EXT]: {legacyEntityId: 7, jointEntityId: 80},
        [roles.NATIONAL_GRID_EXT]: {legacyEntityId: 5, jointEntityId: 45},
        [roles.DUKE_ENERGY_2_EXT]: {legacyEntityId: 3, jointEntityId: 43},
        [roles.WCTEL_BREC_EXT]: {legacyEntityId: 8, jointEntityId: 81},
    }
}

export default roleGroups;