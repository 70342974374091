import { getApiBase } from "_helpers";
import { cookieService } from "_services";

const createDispatch = (entityid) => {
  let body = { entityid };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(`${apiBase}/tel/dispatch`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating Dispatch");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getOneDispatch = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache", // Tells proxies/browsers not to cache
      Pragma: "no-cache", // Legacy HTTP 1.0 cache-busting header
    },
    cache: "no-store", // Fetch API directive to always go to network
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  // was using v2 for testing of new filters

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/dispatch?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching dispatch");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateOneDispatch = (payload, claimId, callback) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/dispatch?claimid=${claimId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error updating claim ${claimId}`);
      }
      if (callback) callback();
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateTenEightDispatch = (claimId) => {
  let body = {};

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/alldispatches/teneight?claimid=${claimId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error updating claim ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

//Dispatch Stopwatch
const updateDispatchTimerPause = (payload) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(`${apiBase}/joint/dispatch/clearDispatch`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating Dispatch");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateDispatchTimerColor = (payload) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/joint/dispatch/updateDispatchStatus`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating Dispatch");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const telecomDispatchService = {
  createDispatch,
  getOneDispatch,
  updateOneDispatch,
  updateTenEightDispatch,
  updateDispatchTimerPause,
  updateDispatchTimerColor,
};
