import { getApiBase } from "_helpers";
import { cookieService } from "_services";
import _uniqueId from "lodash/uniqueId";

const getAllClaims = (
  page,
  rowsPerPage,
  filterParams,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  restrictedStatusIds
) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const apiBase = getApiBase();

  const jwtCookie = process.env.NODE_ENV !== "production" ? `&jwtCookie=${encodeURI(cookieService.get("token"))}` : "";

  const restrictedStatusIdsParam = restrictedStatusIds?.length > 0 ? `&restrictedStatusIds=${restrictedStatusIds}` : "";

  const searchFieldsParam = `&searchFields=${encodeURI(JSON.stringify(searchFields.map((field) => field.value)))}`;

  const searchFieldParam = searchField ? `&searchField=${searchField}` : "";

  return fetch(
    `${apiBase}/tel/claims?rows=${rowsPerPage}&page=${page}${allClaimsCount ? allClaimsCount : ""}${filterParams}${
      search ? `&search=${encodeURI(search)}` : ""
    }${
      isDispatchAdmin ? "&dispatchAdmin=true" : ""
    }${restrictedStatusIdsParam}${searchFieldsParam}${searchFieldParam}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("No claims matching search");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getMyClaims = (
  page,
  rowsPerPage,
  filterParams,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  userLogginIn
) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const apiBase = getApiBase();

  const jwtCookie = process.env.NODE_ENV !== "production" ? `&jwtCookie=${encodeURI(cookieService.get("token"))}` : "";

  const searchFieldsParam = `&searchFields=${encodeURI(JSON.stringify(searchFields.map((field) => field.value)))}`;

  const searchFieldParam = searchField ? `&searchField=${searchField}` : "";

  return fetch(
    `${apiBase}/tel/myclaims?rows=${rowsPerPage}&page=${page}${allClaimsCount ? allClaimsCount : ""}${filterParams}${
      search ? `&search=${encodeURI(search)}` : ""
    }${
      isDispatchAdmin ? "&dispatchAdmin=true" : ""
    }&userID=${userLogginIn}${searchFieldsParam}${searchFieldParam}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("No claims found for this user");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getMyTeamsClaims = (
  page,
  rowsPerPage,
  filterParams,
  search,
  searchField,
  searchFields,
  isDispatchAdmin,
  allClaimsCount,
  userLogginIn
) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const apiBase = getApiBase();

  const jwtCookie = process.env.NODE_ENV !== "production" ? `&jwtCookie=${encodeURI(cookieService.get("token"))}` : "";

  const searchFieldsParam = `&searchFields=${encodeURI(JSON.stringify(searchFields.map((field) => field.value)))}`;

  const searchFieldParam = searchField ? `&searchField=${searchField}` : "";

  return fetch(
    `${apiBase}/tel/myteamclaims?rows=${rowsPerPage}&page=${page}${
      allClaimsCount ? allClaimsCount : ""
    }${filterParams}${search ? `&search=${encodeURI(search)}` : ""}${
      isDispatchAdmin ? "&dispatchAdmin=true" : ""
    }&userID=${userLogginIn}${searchFieldsParam}${searchFieldParam}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("No claims found for this user's teams");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getMyMobileClaims = (page, rowsPerPage, filterParams, search, searchField, searchFields, restrictedStatusIds) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const apiBase = getApiBase();

  const jwtCookie = process.env.NODE_ENV !== "production" ? `&jwtCookie=${encodeURI(cookieService.get("token"))}` : "";

  const restrictedStatusIdsParam = restrictedStatusIds.length > 0 ? `&restrictedStatusIds=${restrictedStatusIds}` : "";

  const searchFieldsParam = `&searchFields=${encodeURI(JSON.stringify(searchFields.map((field) => field.value)))}`;

  const searchFieldParam = searchField ? `&searchField=${searchField}` : "";

  return fetch(
    `${apiBase}/tel/internalmobile/myclaims?rows=${rowsPerPage}&page=${page}${filterParams}${
      search ? `&search=${encodeURI(search)}` : ""
    }${restrictedStatusIdsParam}${searchFieldsParam}${searchFieldParam}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("No claims matching search");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimById = (claimId, restrictedStatusIds) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache", // Tells proxies/browsers not to cache
      Pragma: "no-cache", // Legacy HTTP 1.0 cache-busting header
    },
    cache: "no-store", // Fetch API directive to always go to network
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  let restrictedStatusIdsParam = "";
  if (restrictedStatusIds?.length > 0) {
    restrictedStatusIdsParam = `&restrictedStatusIds=${restrictedStatusIds}`;
  }

  return fetch(`${apiBase}/tel/claim?claimid=${claimId}${restrictedStatusIdsParam}${jwtCookie}`, parameters)
    .then(async (response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      const responseObj = await response.json();

      if (response.status !== 200) {
        if (responseObj.reason) {
          const initialUrl = window.location.href;

          window.history.pushState(window.history.state, "", `${claimId}/access-denied/${responseObj.reason}`);
          window.history.go(0);
          window.history.pushState(window.history.state, "", initialUrl);
          throw new Error(`Access Denied`);
        } else {
          throw new Error(`error fetching claim ${claimId}`);
        }
      }

      return responseObj;
    })
    .catch((err) => {
      throw err;
    });
};

const updateClaim = (claimId, payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/updateclaim?claimid=${claimId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, claimId };
    })
    .catch((err) => {
      throw err;
    });
};

const deleteClaim = (claimId, restrictedStatusIds, trackChanges, data, callback) => {
  let body = {
    // 276: 1, // set inActive field to true
    trackChanges,
    data,
  };

  if (restrictedStatusIds.length > 0) {
    body.restrictedStatusIds = restrictedStatusIds;
  }

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/deleteclaim?claimid=${claimId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (callback) callback();
      return { status: response.status, claimId };
    })
    .catch((err) => {
      throw err;
    });
};

const createClaim = (payload) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/create`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error creating claim");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getCreateClaimFieldRules = (entityId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/schema/create?entityid=${entityId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching field meta data");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSchemaEntityOptions = (lob) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/schema/entityoptions?${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching entity options}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSchemaRdaOptions = (entityId, getAll) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/schema/rda?entityid=${entityId}&getall=${getAll}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching rda options}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getSchemaFilterConditions = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/schema/claimsearchconditions${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching field meta data");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getFilterSets = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/filters/save${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching saved filter sets");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const createFilterSet = (payload) => {
  let body = { ...payload };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/filters/save`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error filter set");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const removeFilterSet = (id) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/filters/save?filterid=${id}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error deleting collection");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const updateFilterSet = (filterSetId, payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/filters/save?filterid=${filterSetId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error updating filter set information");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const checkFilterSetName = (base, name) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/filters/save/name?base=${base}&name=${name}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error checking filter set name");
      }
      return { ...response.json(), status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

const duplicateFilterSet = (id, name) => {
  let body = { name };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/filters/save/duplicate?filterid=${id}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error duplicating filter set");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const uploadFile = (image, { claimId, categoryId, type, privateFile, category, parentCategory, userId, username }) => {
  const dataFile = new FormData();

  const originalFileName = image.name.substr(0, image.name.lastIndexOf("."));
  const fileTypeNative = image.name.substr(image.name.lastIndexOf(".")).toLowerCase();

  // Append base fields
  dataFile.append("file", image, `${claimId} - ${_uniqueId()}`);
  dataFile.append("fileName", `${claimId} - ${category}`);
  dataFile.append("userid", `${userId}`);
  dataFile.append("username", `${username}`);
  dataFile.append("claimId", claimId);
  dataFile.append("categoryId", categoryId);
  dataFile.append("parentCategory", parentCategory);
  dataFile.append("originalFileName", originalFileName);
  dataFile.append("active", true);
  dataFile.append("lob", "TEL");
  dataFile.append("fileTypeNative", fileTypeNative);

  if (process.env.NODE_ENV !== "production") {
    dataFile.append("jwtCookie", cookieService.get("token"));
  }

  const parameters = {
    method: "POST",
    credentials: "include",
    body: dataFile,
  };

  let apiBase = getApiBase();
  return fetch(`${apiBase}/claim/upload-telecom`, parameters)
    .then((response) => {
      if (response.status == 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id: originalFileName };
    })
    .catch((err) => {
      throw err;
    });
};

const handleSaveRotation = ({ file, GCSPath, claimId, fileID, fk_categoryID }) => {
  const dataFile = new FormData();
  dataFile.append("file", file);
  dataFile.append("claimId", claimId);
  dataFile.append("lob", "TEL");
  dataFile.append("GCSPath", GCSPath);
  dataFile.append("fileID", fileID);
  dataFile.append("fk_categoryID", fk_categoryID);
  if (process.env.NODE_ENV !== "production") {
    dataFile.append("jwtCookie", cookieService.get("token"));
  }
  const parameters = {
    method: "POST",
    credentials: "include",
    body: dataFile,
  };
  let apiBase = getApiBase();
  return fetch(`${apiBase}/claim/saveFileRotation`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("Error saving file rotation");
      }
      return {};
    })
    .catch((err) => {
      throw err;
    });
};

const getFiles = (startOffset) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
    credentials: "include",
  };

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  let apiBase = getApiBase();
  return fetch(`${apiBase}/claim/files?offset=${startOffset}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching attachments");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const sortOrder = (payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/claim/saveindex${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 204) {
        throw new Error("Error Updating Order");
      }

      return response;
    })
    .catch((err) => {
      throw err;
    });
};

const updateCategory = (payload) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/claim/savecategory${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 200) {
        throw new Error("Error Updating Categories");
      }

      return response;
    })
    .catch((err) => {
      throw err;
    });
};

const getSignedUrl = (path) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  let apiBase = getApiBase();
  return fetch(`${apiBase}/claim/file?path=${path}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error fetching attachments");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const postPayment = (payload, claimId) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/payment/save?claimid=${claimId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 200) {
        throw new Error("Error posting payment");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimHistory = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claimhistory?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching rda options}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};
/**
 *
 * @param {*} claimId
 * @param {*} ccma Charter Comcast Mdediacom Altice
 * @returns
 */
const getJobCostingInvoice = async (claimId, ccma) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/tel/claim/invoice?claimid=${claimId}&ccma=${ccma}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error(`error fetching TEL ${claimId} Invoice}`);
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const getClaimJobCostingRateCardContractor = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/ratecard/misc?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching error fetching contractor`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimJobCostingRateCardMaterial = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/ratecard/material?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching material rate card`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimJobCostingRateCardLabor = (claimId, ccma) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/ratecard/labor?claimid=${claimId}&ccma=${ccma}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching Labor Rate Card}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimJobCostingRateCardUsic = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing
  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/ratecard/usic?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching USIC Rate Card}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

let claimInvoiceTimeStamp = (claimId) => {
  let body = {};

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/tel/claim/invoice?claimid=${claimId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 200) {
        throw new Error("Error creating invoice id");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const postClaimInHouseLaborItem = async (payload, claimId, invoiceId, ccma, entityid) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  try {
    const response = await fetch(
      `${apiBase}/tel/claim/invoice/labor?claimid=${claimId}&invoiceid=${invoiceId}&ccma=${ccma}&entityid=${entityid}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }

    if (response.status !== 200) {
      throw new Error("Error posting payment");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const postClaimMaterialItem = async (payload, claimId, invoiceId, ccma, entityid) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  try {
    const response = await fetch(
      `${apiBase}/tel/claim/invoice/material?claimid=${claimId}&invoiceid=${invoiceId}&ccma=${ccma}&entityid=${entityid}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }

    if (response.status !== 200) {
      throw new Error("Error posting material item");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const postClaimOtherItem = (payload, claimId, invoiceId) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(`${apiBase}/tel/claim/invoice/other?claimid=${claimId}&invoiceid=${invoiceId}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 200) {
        throw new Error("Error posting payment");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const postClaimUnlistedItem = async (payload, claimId, entityId, invoiceId) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  try {
    const response = await fetch(
      `${apiBase}/tel/claim/invoice/unlisted?claimid=${claimId}&invoiceId=${invoiceId}&entityId=${entityId}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }

    if (response.status !== 200) {
      throw new Error("Error posting payment");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const postClaimContractorItem = async (payload, claimId, invoiceId, ccma, entityid) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  try {
    const response = await fetch(
      `${apiBase}/tel/claim/invoice/contractor?claimid=${claimId}&invoiceid=${invoiceId}&ccma=${ccma}&entityid=${entityid}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }

    if (response.status !== 200) {
      throw new Error("Error posting payment");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const postClaimLOItem = (payload, claimId, invoiceId, entityId) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(
    `${apiBase}/tel/claim/invoice/lo?claimid=${claimId}&invoiceid=${invoiceId}&entityid=${entityId}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }

      if (response.status !== 200) {
        throw new Error("Error posting payment");
      }

      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const jobCostingRemoveOtherItem = (itemId, invoiceId) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/tel/claim/invoice/other?invoiceid=${invoiceId}&invoiceotheritemid=${itemId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error removing other item");
      }
      return { id: itemId, status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

const jobCostingRemoveLaborItem = async (itemId, invoiceId, ccma) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/tel/claim/invoice/labor?invoiceid=${invoiceId}&invoicelaboritemid=${itemId}&ccma=${ccma}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error removing labor item");
    }
    return { id: itemId, status: response.status };
  } catch (err) {
    throw err;
  }
};

const jobCostingRemoveMaterialItem = async (itemId, invoiceId, ccma) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/tel/claim/invoice/material?invoiceid=${invoiceId}&invoicematerialitemid=${itemId}&ccma=${ccma}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error removing material item");
    }
    return { id: itemId, status: response.status };
  } catch (err) {
    throw err;
  }
};

const jobCostingRemoveContractorItem = async (itemId, invoiceId, ccma) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(
      `${apiBase}/tel/claim/invoice/contractor?invoiceid=${invoiceId}&invoicecontractoritemid=${itemId}&ccma=${ccma}${jwtCookie}`,
      parameters
    );
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error removing contractor item");
    }
    return { id: itemId, status: response.status };
  } catch (err) {
    throw err;
  }
};

const jobCostingRemoveLOItem = (itemId, invoiceId, claimId, entityId) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/tel/claim/invoice/lo?invoiceid=${invoiceId}&invoiceloitemid=${itemId}&claimid=${claimId}&entityid=${entityId}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error removing contractor item");
      }
      return { id: itemId, status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

const uploadJobCostingFile = (file, name, claimId, userId, username) => {
  const dataFile = new FormData();

  dataFile.append("file", file, `${name} - ${_uniqueId()}`);
  dataFile.append("fileName", `${name}`);
  dataFile.append("claimId", claimId);
  dataFile.append("parentCategory", "Investigation");
  dataFile.append("categoryId", 7);
  dataFile.append("userid", `${userId}`);
  dataFile.append("username", `${username}`);
  // dataFile.append("originalFileName", originalFileName);
  // dataFile.append("fileNameWithType", `${name}.pdf`);
  dataFile.append("fileTypeNative", ".pdf");
  dataFile.append("lob", "TEL"); // TEL , POWGAS , FRELO
  dataFile.append("active", true);
  // dataFile.append("privateFile", true);

  if (process.env.NODE_ENV !== "production") {
    dataFile.append("jwtCookie", cookieService.get("token"));
  }

  const parameters = {
    method: "POST",
    credentials: "include",
    body: dataFile,
  };

  let apiBase = getApiBase();

  return fetch(`${apiBase}/claim/upload-telecom`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id: `${claimId} - Job Costing` };
    })
    .catch((err) => {
      throw err;
    });
};

const claimUploadDocument = (file, name, claimId, userId, username, parent, category, fileType = ".pdf") => {
  const dataFile = new FormData();
  dataFile.append("file", file, `${name} - ${_uniqueId()}`);
  dataFile.append("fileName", `${name}`);
  dataFile.append("claimId", claimId);
  dataFile.append("categoryId", category);
  dataFile.append("parentCategory", parent);
  dataFile.append("userid", `${userId}`);
  dataFile.append("username", `${username}`);
  dataFile.append("fileTypeNative", fileType);
  dataFile.append("lob", "TEL"); // TEL , POWGAS , FRELO
  dataFile.append("active", true);

  if (process.env.NODE_ENV !== "production") {
    dataFile.append("jwtCookie", cookieService.get("token"));
  }

  const parameters = {
    method: "POST",
    credentials: "include",
    body: dataFile,
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(`${apiBase}/claim/upload-telecom`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return { status: response.status, id: `${claimId} - document` };
    })
    .catch((err) => {
      throw err;
    });
};

const claimConvertDocument = (pdf, name) => {
  const dataFile = new FormData();
  dataFile.append("file", pdf);
  dataFile.append("fileName", name);
  dataFile.append("originalname", name);
  dataFile.append("fileTypeNative", "pdf");

  if (process.env.NODE_ENV !== "production") {
    dataFile.append("jwtCookie", cookieService.get("token"));
  }

  const parameters = {
    method: "POST",
    credentials: "include",
    body: dataFile,
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  return fetch(`${apiBase}/tel/claim/doc/convert`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getInvoiceDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/payment?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching payment document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimDoc = (claimId, letterType, encodeContacts, encodeReason) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/tel/claim/doc/template/claim?claimid=${claimId}&lettertype=${letterType}${encodeContacts}${encodeReason}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimDocSchemaReason = async (entityId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(`${apiBase}/tel/claim/doc/schema/reason${jwtCookie}`, parameters);
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error(`error fetching reason options`);
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const addClaimDocSchemaReason = async (LetterReason) => {
  let body = { LetterReason };
  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  try {
    const response = await fetch(`${apiBase}/tel/claim/doc/schema/reason`, parameters);
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("Error creating claim");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const editClaimDocSchemaReason = async (LRID, LetterReason) => {
  let body = { LetterReason };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  try {
    const response = await fetch(`${apiBase}/tel/claim/doc/schema/reason?LRID=${LRID}`, parameters);
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("Error editing Letter Reason");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const removeClaimDocSchemaReason = async (LRID) => {
  const parameters = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(`${apiBase}/tel/claim/doc/schema/reason?LRID=${LRID}${jwtCookie}`, parameters);
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("Error creating claim");
    }
    return await response.json();
  } catch (err) {
    throw err;
  }
};

const getClaimDocSchemaContacts = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/schema/contacts${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching contact options`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimDocSchemaLastSentence = (entityId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/schema/lastsentence?entityid=${entityId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching last sentence options`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimAttachmentSchemaImageCategory = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/schema/filetype/image${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching attachment file image schema`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimAttachmentSchemaVideoCategory = () => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `?jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/schema/filetype/video${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching attachment file video schema`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimAttachmentSchemaDocumentCategory = (parentType) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/schema/filetype/document?parentType=${parentType}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching attachment file video schema`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimDocSchemaDigLaws = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/schema/dig-laws?claimId=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching dig laws options`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimNarrativeCreatorDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/narrative-creator?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimIntentToBillDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/intent-to-bill?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimPromissoryNoteDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/promissory-note?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimLegalFinalNoticeDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/legal-notice?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimFinalNoticeDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/final-notice?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimReleaseLetterDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/release-letter?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimSecondNoticeDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/second-notice?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimPaymentReceiptDoc = (claimId, ledgerid) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/tel/claim/doc/template/payment-receipt?claimid=${claimId}&ledgerid=${ledgerid}${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

const getClaimBalanceOwedDoc = (claimId) => {
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/tel/claim/doc/template/balance-owed?claimid=${claimId}${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error(`error fetching claim document ${claimId}`);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

// const getLogo = (entityLogoName) => {
//   const parameters = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     credentials: "include",
//   };

//   let apiBase = getApiBase();
//   // let apiBase = "http://localhost:3000/api"; // local host testing
//   let jwtCookie = "";
//   if (process.env.NODE_ENV !== "production") {
//     jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
//   }

//   return fetch(
//     `${apiBase}/public/images?file=${entityLogoName}${jwtCookie}`,
//     parameters
//   )
//     .then((response) => {
//       if (response.status === 403) {
//         throw new Error("Invalid Auth Token");
//       }
//       if (response.status !== 200) {
//         throw new Error(`error fetching logo`);
//       }
//       return response.json();
//     })
//     .catch((err) => {
//       throw err;
//     });
// };
const deleteLedger = (ledgerId, claimId) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/joint/ledger/delete?ledgerid=${ledgerId}&claimid=${claimId}&lobid=1${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error removing ledger");
      }
      return { id: ledgerId, status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

const updateLedger = ({ id, ...payload }, watchId, cb) => {
  let body = {
    ...payload,
  };

  if (process.env.NODE_ENV !== "production") {
    body = {
      ...body,
      jwtCookie: cookieService.get("token"),
    };
  }

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  // let apiBase = "http://localhost:3000/api"; // local host testing
  let apiBase = getApiBase();

  return fetch(`${apiBase}/joint/ledger/put?ledgerId=${id}&claimid=${watchId}&lobid=1`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (cb) cb();
      return { status: response.status, id };
    })
    .catch((err) => {
      throw err;
    });
};

const deleteStatusNote = (watchId) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(`${apiBase}/joint/statusnotes/delete?id=${watchId}&lobid=1${jwtCookie}`, parameters)
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error removing  Status Note");
      }
      return { id: watchId, status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

const deleteActionNote = (actionId, claimId) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  let apiBase = getApiBase();
  // let apiBase = "http://localhost:3000/api"; // local host testing

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  return fetch(
    `${apiBase}/joint/actionnotes/delete?actionid=${actionId}&claimid=${claimId}&lobid=1${jwtCookie}`,
    parameters
  )
    .then((response) => {
      if (response.status === 403) {
        throw new Error("Invalid Auth Token");
      }
      if (response.status !== 200) {
        throw new Error("error removing  Status Note");
      }
      return { id: claimId, status: response.status };
    })
    .catch((err) => {
      throw err;
    });
};

const saveColumnFilters = async (filters) => {
  const filterArr = Object.keys(filters).map((key) => {
    return { [key]: filters[key] };
  });
  let body = {
    filters: filterArr,
  };

  const parameters = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  let apiBase = getApiBase();

  let jwtCookie = "";
  if (process.env.NODE_ENV !== "production") {
    jwtCookie = `&jwtCookie=${encodeURI(cookieService.get("token"))}`;
  }

  try {
    const response = await fetch(`${apiBase}/joint/claimcolumns?lobid=1${jwtCookie}`, parameters);
    if (response.status === 403) {
      throw new Error("Invalid Auth Token");
    }
    if (response.status !== 200) {
      throw new Error("error saving column filters");
    }
    return { status: response.status };
  } catch (err) {
    throw err;
  }
};

export const telecomClaimService = {
  getAllClaims,
  getMyClaims,
  getMyTeamsClaims,
  getMyMobileClaims,
  getClaimById,
  updateClaim,
  deleteClaim,
  createClaim,
  getCreateClaimFieldRules,
  getSchemaEntityOptions,
  getSchemaRdaOptions,
  getSchemaFilterConditions,
  getFilterSets,
  createFilterSet,
  removeFilterSet,
  updateFilterSet,
  checkFilterSetName,
  duplicateFilterSet,
  uploadFile,
  handleSaveRotation,
  getFiles,
  sortOrder,
  updateCategory,
  getSignedUrl,
  postPayment,
  getClaimHistory,
  getJobCostingInvoice,
  getClaimJobCostingRateCardContractor,
  getClaimJobCostingRateCardMaterial,
  getClaimJobCostingRateCardLabor,
  getClaimJobCostingRateCardUsic,
  claimInvoiceTimeStamp,
  postClaimInHouseLaborItem,
  postClaimMaterialItem,
  postClaimOtherItem,
  postClaimUnlistedItem,
  postClaimContractorItem,
  postClaimLOItem,
  jobCostingRemoveOtherItem,
  jobCostingRemoveLaborItem,
  jobCostingRemoveMaterialItem,
  jobCostingRemoveContractorItem,
  jobCostingRemoveLOItem,
  uploadJobCostingFile,
  claimUploadDocument,
  claimConvertDocument,
  getInvoiceDoc,
  getClaimDoc,
  getClaimDocSchemaReason,
  getClaimDocSchemaContacts,
  getClaimDocSchemaLastSentence,
  getClaimAttachmentSchemaImageCategory,
  getClaimAttachmentSchemaVideoCategory,
  getClaimAttachmentSchemaDocumentCategory,
  getClaimDocSchemaDigLaws,
  getClaimNarrativeCreatorDoc,
  getClaimIntentToBillDoc,
  getClaimPromissoryNoteDoc,
  getClaimLegalFinalNoticeDoc,
  getClaimFinalNoticeDoc,
  getClaimReleaseLetterDoc,
  getClaimSecondNoticeDoc,
  getClaimPaymentReceiptDoc,
  getClaimBalanceOwedDoc,
  deleteLedger,
  updateLedger,
  deleteStatusNote,
  deleteActionNote,
  addClaimDocSchemaReason,
  editClaimDocSchemaReason,
  removeClaimDocSchemaReason,
  saveColumnFilters,
  //getLogo
};
